import { useEffect, useState } from "react";
import Modal from "./Modal";
import { IoMdJet, IoIosThumbsDown, IoMdPlanet, IoMdTimer, IoIosCart, IoIosRainy, IoMdRemoveCircle, IoMdTrophy } from "react-icons/io";
import ReactSlider from 'react-slider';
import { toast } from "react-toastify";

/**
 * data.mode : 0 // Buy Tokens with specified eth 
 * data.mode : 1 // Sell Tokens with specified token
 * data.mode : 2 // Buy Tokens with specified eth with condition : price is lower than limit
 * data.mode : 3 // Sell Tokens with specified tokens with condition : price is higher than limit
 *  */
export default function SwapDialog({ isOpen, onOK, onCancel, data }) {
    const [limit, setLimit] = useState(data.limit);
    const [wallets, setWallets] = useState(data.wallets);
    const [amounts, setAmounts] = useState([]);
    const [percent, setPercent] = useState(100);
    const [limitBuy, setLimitBuy] = useState(0.06); // 0.06 ETH

    const handleOK = () => {
        if (percent == 0) {
            toast.error("Zero Percent Error !");
        } else {
            onOK(percent, limit);
            setPercent(100);
        }
    };

    const handleCancel = () => {
        onCancel(data);
        setPercent(100);
    };

    useEffect(() => {
        setWallets(data.wallets);
        if (data.wallets && data.wallets.length > 0) {
            const initData = data.wallets.map(wallet => wallet.amount);
            setAmounts(initData);

            console.log("init amount : ", initData);
        }
    }, [data.wallets])

    useEffect(() => {
        let newAmounts = [];
        for (let i = 0; i < amounts.length; i++) {
            let amountValue = Number(data.wallets[i].amount);
            amountValue = amountValue * percent / 100;
            newAmounts.push(amountValue);
        }
        setAmounts(newAmounts);

        console.log("Percent : ", percent);
        console.log("Amounts : ", newAmounts);
    }, [percent])

    useEffect(() => {
        // for (let i = 0; i < amounts.length; i++) {
        //     let amountValue = Number(amounts[i]);
        //     amountValue = amountValue * percent / 100;
        //     amounts[i] = amountValue;
        // }
        // setAmounts(amounts);
    }, [isOpen])

    const handleChangePercent = (newValue) => {
        // If selling on the limit orders && unable to set the sell percent
        if (data.mode == 3) {
            setPercent(100);
        } else {
            setPercent(Number(newValue));
        }
    };

    const handleMax = () => {
        setPercent(100);
        // setAmount(data.amount);
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="flex flex-col w-[600px] font-sans bg-gray-dark" style={{
                border: '1px solid gray'
            }}>
                <div className="flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight">
                    <div className="flex flex-row items-center font-sans text-2xl font-medium text-white uppercase" style={{ columnGap: '15px' }}>
                        <IoMdJet />
                        Market Making Percent
                        <IoMdJet />
                    </div>
                </div>
                <div className="mt-4 mx-4 bg-gray-dark">
                    <div className="font-sans text-xs uppercase text-gray-normal">
                        Token Address
                    </div>
                    <input
                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                        placeholder="Enter Wallet Count"
                        value={data.tokenAddress}
                        readOnly
                    />

                </div>
                <div className="flex flex-col bg-gray-dark" style={{
                    height: '400px',
                    overflowY: 'scroll',
                    borderBottom: '1px solid gray'
                }}>
                    <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
                        {wallets && wallets.map((item, index) => {
                            return (
                                <div className="flex flex-row w-full" style={{
                                    columnGap: '10px'
                                }}>
                                    <div className="mt-4 w-full">
                                        <div className="font-sans text-xs uppercase text-gray-normal">
                                            Wallet Address <span className="text-white">{index + 1}</span>
                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter Wallet Count"
                                            value={item.address}
                                            readOnly
                                        />
                                    </div>

                                    <div className="mt-4 w-full">
                                        <div className="flex flex-row font-sans text-xs uppercase text-gray-normal flex flex-row items-center">
                                            <IoMdPlanet />
                                            &nbsp;<span style={{ color: 'white' }}>{item.amount}</span>&nbsp; {data.mode % 2 === 0 ? "ETH" : "Tokens"}
                                            <span className="pl-1 text-green-normal">*</span>

                                            {data.mode % 2 == 0 && Number(amounts[index]) < Number("0.06") && <span className="mx-1" style={{
                                                color: 'red',
                                                display: 'flex', flexDirection: 'row', alignItems: 'center'
                                            }}>
                                                <IoIosThumbsDown />
                                                0.06 ETH
                                            </span>}

                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter Wallet Count"
                                            value={amounts[index]}
                                            onChange={(e) => {
                                                // setAmount(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>);
                        })}



                    </div>
                </div>

                <div className="flex flex-col bg-gray-dark px-4"  >



                    <div className="mt-4 " style={{
                        marginRight: '-20px'
                    }}>
                        <div style={{ color: "white" }}>Percent : {percent} %</div>
                        <ReactSlider
                            min={0}
                            max={100}
                            value={percent} // Use state variable here
                            onChange={handleChangePercent} // Directly pass new value
                            style={{ width: '100%', height: '100px', background: 'red' }} // Ensure the slider is visible
                            thumbClassName="thumb" // Optional: for custom styling
                            trackClassName="track" // Optional: for custom styling
                            className="react-slider"

                        />
                    </div>

                    {data.mode >= 2 &&
                        <>
                            <div className="font-sans text-xs uppercase text-gray-normal mt-2">
                                {data.mode == 2 && <span style={{ color: 'white' }}>Order Buy when the price is down to this limit $ :</span>}
                                {data.mode == 3 && <span style={{ color: 'white' }}>Order Sell when the price is up to this limit $ :</span>}
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter Wallet Count"
                                value={limit}
                                onChange={(e) => {
                                    setLimit(e.target.value);
                                }}
                            />
                        </>
                    }

                    <div className="flex items-center justify-center gap-5 my-5 w-full">
                        <button
                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full justify-center"
                            style={{ background: 'rgba(255,0,0,0.1)', color: 'white' }}
                            onClick={handleMax}
                        >
                            <IoMdTrophy className="text-lg text-green-normal mr-2" />
                            Max
                        </button>
                        <button
                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full justify-center"
                            style={{ background: 'rgba(0,255,0,0.1)', color: 'white' }}
                            // disabled={data.mode % 2 == 0 && Number(amounts[index]) < Number("0.06")}
                            onClick={handleOK}
                        >
                            {data.mode % 2 === 0 &&
                                <>
                                    <IoIosCart className="text-lg text-green-normal mr-2" />
                                    Buy
                                    <IoMdTimer className="text-lg text-green-normal ml-1" />
                                </>
                            }
                            {data.mode % 2 === 1 &&
                                <>
                                    <IoIosCart className="text-lg text-green-normal mr-2" />
                                    Sell
                                    <IoMdTimer className="text-lg text-green-normal ml-1" />
                                </>
                            }
                        </button>
                        <button
                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full justify-center"
                            style={{ background: 'rgba(0,0,120,0.1)', color: 'white' }}
                            onClick={handleCancel}
                        >
                            <IoMdRemoveCircle className="text-lg text-green-normal mr-2" />
                            Cancel
                        </button>
                    </div>

                </div>

            </div>
        </Modal >
    );
}
