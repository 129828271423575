
import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Listbox, Popover } from "@headlessui/react";
import { FaDatabase, FaEllipsisV, FaEthereum, FaExclamationTriangle, FaGasPump, FaQuestion, FaRegCopy, FaSave, FaSync } from "react-icons/fa";
import {
    IoIosSave, IoIosRadioButtonOn, IoIosAirplane, IoIosCloseCircle, IoMdKeypad,
    IoMdFastforward, IoMdBonfire, IoIosStarHalf, IoMdCog, IoMdSwap, IoMdFlashOff, IoIosCart, IoIosRainy, IoIosAddCircle, IoIosArrowDown, IoIosClose, IoIosDownload, IoIosRefresh
} from "react-icons/io";
import { useAccount, useChainId } from "wagmi";
import { ethers } from "ethers";
import axios from "axios";
import BigNumber from "bignumber.js";

import { AppContext } from "../App";
import ZombieDialog from "../components/Dialogs/ZombieDialog";
import NewWalletDialog from "../components/Dialogs/NewWalletDialog";
import SwapDialog from "../components/Dialogs/SwapDialog";
import TokenAmountDialog from "../components/Dialogs/TokenAmountDialog";
import EthAmountDialog from "../components/Dialogs/EthAmountDialog";
import SimulationDialog from "../components/Dialogs/SimulationDialog";
import { useEthersSigner, useEthersProvider } from "../utils/provider";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { TEMPLATES } from "../utils/constants";
import goliveABI from "../abi/IGoLive.json";
import tokenABI from "../abi/ITradingToken.json";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function BuySellBot({ className }) {

    const {
        SERVER_URL,
        currentMarket,
        setCurrentMarket,
        setOpenLoading,
        setLoadingPrompt,
        updateMarket,
        notifyStatus,
        setNotifyStatus,
        marketWalletBalanceData,
        updateMarketBalances,
    } = useContext(AppContext);

    const chainId = useChainId();
    const { isConnected } = useAccount();
    const [targetWallet, setTargetWallet] = useState("");
    const [runningStatus, setRunningStatus] = useState("ready");
    const disabled = false;
    const [gasPrice, setGasPrice] = useState("0");
    const [gasPriceMultiplier, setGasPriceMultiplier] = useState("");
    const provider = useEthersProvider(chainId);
    const signer = useEthersSigner(chainId);

    const [token, setToken] = useState("");
    const [tokenInfo, setTokenInfo] = useState({ decimals: "", totalSupply: "" });
    const [zombieWallet, setZombieWallet] = useState({ address: "", privateKey: "" });
    const [zombieDialog, setZombieDialog] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [orderState, setOrderState] = useState(false);


    // MBC - Select the market.token.address
    const [targetChain, setTargetChain] = useState("ethereum");
    const [nativeCoin, setNativeCoin] = useState("ETH");

    console.log("Current Market : ", currentMarket);

    const [tokenAddress, setTokenAddress] = useState(currentMarket.token ? currentMarket.token.address : "0xc98b2d550d8d123f8e6950e0758305e88511b037");
    // const [cTokenAddress, setCTokenAddress] = useState(currentMarket.token ? currentMarket.token.address : "");
    const [cTokenAddress, setCTokenAddress] = useState(currentMarket.token ? currentMarket.token.address : "0xc98b2d550d8d123f8e6950e0758305e88511b037");
    const [showGraph, setShowGraph] = useState(false);
    const [showTransaction, setShowTransaction] = useState(false);
    const [showOrder, setShowOrder] = useState('market');

    // Wallet management
    const [walletAllChecked, setWalletAllChecked] = useState(false);
    const [walletChecked, setWalletChecked] = useState([]);
    const [copied, setCopied] = useState({});
    const [walletEthBalance, setWalletEthBalance] = useState([]);
    const [walletTokenBalance, setWalletTokenBalance] = useState([]);
    const [walletTokenAmount, setWalletTokenAmount] = useState([]);
    const [walletEthAmount, setWalletEthAmount] = useState([]);
    const [ethAmountDialog, setEthAmountDialog] = useState(false);

    const [swapData, setSwapData] = useState({
        mode: 0, // 0 : buy, 1 : sell
        condition: false, // false : buy / sell now ... true : buy/sell when on the condition
        amount: 0,
        limit: 0,
        wallets: []
    });
    const [swapDialog, setSwapDialog] = useState(false);

    const [newWalletDialog, setNewWalletDialog] = useState(false);
    const [ethToDisperse, setEthToDisperse] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [marketIndex, setMarketIndex] = useState(0);

    useEffect(() => {
        if (currentMarket.token || currentMarket.zombies) {

            setToken(currentMarket.token.address);
            setZombieWallet({
                address: currentMarket.zombie,
                privateKey: "",
            });
        }
        else {
            setToken("");
            setZombieWallet({ address: "", privateKey: "" });
            setWalletAllChecked(false);
            setWalletChecked([]);
        }
    }, [currentMarket.token, currentMarket.zombie, refresh]);

    useEffect(() => {
        const getTokenInfo = async (token, provider) => {
            try {
                console.log("Getting token info...", token);
                const tokenContract = new ethers.Contract(token, tokenABI, provider);
                const decimals = await tokenContract.decimals();
                const totalSupply = await tokenContract.totalSupply();
                setTokenInfo({
                    decimals: decimals.toString(),
                    totalSupply: new BigNumber(totalSupply.toString() + "e-" + decimals.toString()).toFixed(0)
                });
            }
            catch (err) {
                console.log(err);
                setTokenInfo({
                    decimals: "",
                    totalSupply: "",
                });
            }
        }
        if (isValidAddress(token)) {
            getTokenInfo(token, provider);
        }
        else {
            setTokenInfo({
                decimals: "",
                totalSupply: "",
            });
        }
    }, [token, provider, refresh]);

    useEffect(() => {
        if (currentMarket.wallets) {
            if (currentMarket.wallets.length !== walletChecked.length) {
                const newWalletChecked = currentMarket.wallets.map(() => false);
                setWalletChecked(newWalletChecked);
                setWalletAllChecked(false);
            }

            setWalletEthBalance(currentMarket.wallets.map(() => "-"));
            setWalletTokenBalance(currentMarket.wallets.map(() => "0"));
            setWalletTokenAmount(currentMarket.wallets.map((item) => "0"));
            setWalletEthAmount(currentMarket.wallets.map(item => item.initialEthAmount));
        }
        else {
            setWalletEthBalance([]);
            setWalletTokenBalance([]);
            setWalletTokenAmount([]);
            setWalletEthAmount([]);
        }
    }, [currentMarket.wallets, walletChecked.length, refresh]);

    useEffect(() => {
        if (currentMarket.token && marketWalletBalanceData.address === currentMarket.token.address && marketWalletBalanceData.token.length === walletTokenBalance.length) {
            setWalletTokenBalance(marketWalletBalanceData.token);
        }
    }, [currentMarket.token, marketWalletBalanceData.address, marketWalletBalanceData.token, walletTokenBalance.length, refresh]);

    useEffect(() => {
        if (currentMarket.token && marketWalletBalanceData.address === currentMarket.token.address && marketWalletBalanceData.eth.length === walletEthBalance.length) {
            setWalletEthBalance(marketWalletBalanceData.eth);
        }
    }, [currentMarket.token, marketWalletBalanceData.address, marketWalletBalanceData.eth, walletEthBalance.length, refresh]);


    useEffect(() => {
        if (notifyStatus.tag === "MARKET_BUY_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed !");
            else
                toast.warn("Failed !");

            if (notifyStatus.market) {
                updateMarket(notifyStatus.market);
                if (currentMarket._id === notifyStatus.market._id) {
                    setCurrentMarket(notifyStatus.market);
                }
            }
            const _wallets = currentMarket.wallets.map((item) => item.address);
            updateMarketBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "MARKET_ORDERSET_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed !");
            else
                toast.warn("Failed !");

            if (notifyStatus.market) {
                updateMarket(notifyStatus.market);
                if (currentMarket._id === notifyStatus.market._id)
                    setCurrentMarket(notifyStatus.market);
            }
            const _wallets = currentMarket.wallets.map((item) => item.address);
            updateMarketBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "MARKET_SELL_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed !");
            else
                toast.warn("Failed !");

            if (notifyStatus.market) {
                updateMarket(notifyStatus.market);
                if (currentMarket._id === notifyStatus.market._id)
                    setCurrentMarket(notifyStatus.market);
            }
            const _wallets = currentMarket.wallets.map((item) => item.address);
            updateMarketBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "MARKET_COLLECT_ALL_ETH") {

            if (notifyStatus.success)
                toast.success("Succeed to collect all ETH!");
            else
                toast.warn("Failed to collect all ETH!");

            if (notifyStatus.market) {
                updateMarket(notifyStatus.market);
                if (currentMarket._id === notifyStatus.market._id)
                    setCurrentMarket(notifyStatus.market);
            }
            const _wallets = currentMarket.wallets.map((item) => item.address);
            updateMarketBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "MARKET_DISPERSE_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed to Disperse ETH!");
            else
                toast.warn("Failed to DisperseETH!");

            if (notifyStatus.market) {
                updateMarket(notifyStatus.market);
                if (currentMarket._id === notifyStatus.market._id) {
                    setCurrentMarket(notifyStatus.market);
                }
            }
            const _wallets = currentMarket.wallets.map((item) => item.address);
            updateMarketBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        }
    }, [notifyStatus, currentMarket._id]);



    const updateEthToDisperse = (checkWallets = walletChecked) => {
        let sum = 0;
        for (let j = 0; j < walletEthAmount.length; j++) {
            if (checkWallets[j])
                sum += Number(walletEthAmount[j]);
        }
        setEthToDisperse(sum.toFixed(5));
    }


    const handleEnterPress = (e) => {
        if (e.key == 'Enter') {
            setToken(e.target.value);
            setCTokenAddress(e.target.value);
        }
    }

    const handleGraphChange = () => {
        if (!showGraph) {
            setShowGraph(true);
            setOpenLoading(true);
            setTimeout(() => {
                setOpenLoading(false);
            }, 3000);

        } else {
            setShowGraph(false);
            setOpenLoading(true);
            setTimeout(() => {
                setOpenLoading(false);
            }, 100);
        }
        setShowGraph(!showGraph);
    }

    const handleQueryGasPrice = async () => {
        if (isConnected) {
            setLoadingPrompt("Querying gas price...");
            setOpenLoading(true);
            try {
                const feeData = await provider.getFeeData();
                setGasPrice(Number(new BigNumber(feeData.gasPrice.toString() + "e-9")).toFixed(1));
                console.log("gasPrice:", feeData.gasPrice, "maxFeePerGas:", feeData.maxFeePerGas, "maxPriorityFeePerGas:", feeData.maxPriorityFeePerGas);
            }
            catch (err) {
                setGasPrice("0");
            }
            setOpenLoading(false);
        }
    };


    const handleOKMinMaxEthAmounts = async (minAmount, maxAmount) => {

        console.log("walletChecked : ", walletChecked);

        function getRandomNumber(min, max) {
            const ret = (Math.random() * (max - min)) + min;
            console.log("min : ", min, " max : ", max, " ret : ", ret);
            return ret.toFixed(3);
        }

        try {
            let minX = -1;
            let maxX = -1;
            if (minAmount.charAt(minAmount.length - 1) === '%') {
                minX = Number(minAmount.slice(0, minAmount.length - 1));
                minX = Number(tokenInfo.totalSupply) * minX / 100;
            }
            else
                minX = Number(minAmount);

            if (isNaN(minX) || minX <= 0) {
                toast.warn("Invalid minimum amount");
                return;
            }

            if (maxAmount.charAt(maxAmount.length - 1) === '%') {
                maxX = Number(maxAmount.slice(0, maxAmount.length - 1));
                maxX = Number(tokenInfo.totalSupply) * maxX / 100;
            }
            else
                maxX = Number(maxAmount);

            if (isNaN(maxX) || maxX <= 0) {
                toast.warn("Invalid maximum amount");
                return;
            }

            if (minX > maxX) {
                const t = minX;
                minX = maxX;
                maxX = t;
            }

            let newWalletEthAmount = [...walletEthAmount];
            for (let i = 0; i < newWalletEthAmount.length; i++) {
                if (walletChecked[i])
                    newWalletEthAmount[i] = getRandomNumber(minX, maxX);
            }
            setWalletEthAmount(newWalletEthAmount);
            setEthAmountDialog(false);
            updateEthToDisperse();
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid minimum/maximum amount");
        }

        // setTokenAmountDialog(false);
        setEthAmountDialog(false);
    };

    const handleTransactionChange = () => {
        if (!showTransaction) {
            setShowTransaction(true);
            if (showGraph) {
                setOpenLoading(true);
                setTimeout(() => {
                    setOpenLoading(false);
                }, 3000);
            }

        } else {
            setShowTransaction(false);
            if (showGraph) {
                setOpenLoading(true);
                setTimeout(() => {
                    setOpenLoading(false);
                }, 1000);
            }
        }
        setShowTransaction(!showTransaction);
    }

    const handleShowMarket = () => {
        setShowOrder('market');
    }

    const handleShowLimit = () => {
        setShowOrder('limit');
    }

    const copyToClipboard = async (key, text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
            setCopied({
                ...copied,
                [key]: true,
            });
            setTimeout(() => setCopied({
                ...copied,
                [key]: false,
            }), 2000);
        }
        else
            console.error('Clipboard not supported');
    };

    const handleWalletAllChecked = (e) => {
        const newWalletAllChecked = !walletAllChecked;
        setWalletAllChecked(newWalletAllChecked);
        setWalletChecked(walletChecked.map(() => newWalletAllChecked));

        updateEthToDisperse(walletChecked.map(() => newWalletAllChecked));
    };

    const handleWalletChanged = (index, key, value) => {
        console.log("Wallet changed:", index, key, value);
        if (key === "checked") {
            let newWalletChecked = [...walletChecked];
            newWalletChecked[index] = !newWalletChecked[index];
            setWalletChecked(newWalletChecked);

            let newWalletAllChecked = true;
            for (let i = 0; i < newWalletChecked.length; i++)
                newWalletAllChecked &&= newWalletChecked[i];
            setWalletAllChecked(newWalletAllChecked);

            let sum = 0;
            for (let j = 0; j < newWalletChecked.length; j++) {
                if (newWalletChecked[j]) {
                    sum += Number(walletEthAmount[j]);
                }
            }
            setEthToDisperse(sum.toFixed(5));
        }
        else if (key === "token_amount") {
            let newWalletTokenAmount = [...walletTokenAmount];
            newWalletTokenAmount[index] = value;
            setWalletTokenAmount(newWalletTokenAmount);
        }
        else if (key === "eth_amount") {
            let newWalletETHAmount = [...walletEthAmount];
            newWalletETHAmount[index] = value;
            setWalletEthAmount(newWalletETHAmount);
        }
    };

    const handleSetZombieWallet = () => {
        setZombieDialog(true);
    };

    const handleOKNewWallet = async (walletCount, fresh) => {
        let count = 0;
        try {
            count = parseInt(walletCount);
        }
        catch (err) {
            console.log(err);
        }

        if (isNaN(count) || count < 0 || count > 90) {
            toast.warn("Invalid wallet count, wallet count must be in the range 1-90");
            return;
        }

        setNewWalletDialog(false);
        setLoadingPrompt("Generating new wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/market/generate-wallets`,
                {
                    marketId: currentMarket._id,
                    count: walletCount,
                    fresh: fresh,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            const newCurrentMarket = {
                ...currentMarket,
                wallets: data.market.wallets,
            };
            updateMarket(newCurrentMarket);
            setCurrentMarket(newCurrentMarket);
            toast.success("New wallets has been generated successfully");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to generate new wallets!");
        }
        setOpenLoading(false);
    };

    const handleCollectAllEth = async () => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(targetWallet)) {
            toast.warn("Please input wallet to send ETH!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);

        setLoadingPrompt("Collecting all ETH...");
        setOpenLoading(true);
        try {
            let wallets = [];
            for (let i = 0; i < currentMarket.wallets.length; i++) {
                if (walletChecked[i]) {
                    wallets = [
                        ...wallets,
                        currentMarket.wallets[i].address,
                    ];
                }
            }

            setRunningStatus("collecting");
            await axios.post(`${SERVER_URL}/api/v1/market/collect-all-eth`,
                {
                    marketId: currentMarket._id,
                    chainId,
                    targetWallet,
                    wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to collect all ETH!");
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleDisperseETH = async () => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        try {
            setLoadingPrompt("Dispersing Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            currentMarket.wallets.map((item, index) => {
                if (walletChecked[index]) {
                    buyItems.push({
                        address: item.address,
                        initialEthAmount: walletEthAmount[index]
                    });
                }
            });

            if (buyItems.length > 0) {
                setRunningStatus("disperse");
                await axios.post(`${SERVER_URL}/api/v1/market/disperse`,
                    {
                        chainId,
                        marketId: currentMarket._id,
                        wallets: buyItems
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": localStorage.getItem("access-token"),
                        },
                    }
                );
            } else {
                toast.warn("Please select the wallets.");
                setOpenLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
        }
    }

    const handleBuyTokens = async (percent = 100) => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            let index = marketIndex;

            for (let i = 0; i < currentMarket.wallets.length; i++) {
                if (walletChecked[i]) {
                    buyItems.push({
                        address: currentMarket.wallets[i].address,
                        initialEthAmount: walletEthBalance[i]
                    });
                }
            }

            setRunningStatus("buying");
            axios.post(`${SERVER_URL}/api/v1/market/buy`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    percent: percent,
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleCommand_Order = async () => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        if (currentMarket) {
            let command = 'start';
            if (currentMarket.status == 'running')
                command = 'stop';

            try {
                setLoadingPrompt("Starting Orders ...");
                setOpenLoading(true);

                axios.post(`${SERVER_URL}/api/v1/market/command_order`,
                    {
                        marketId: currentMarket._id,
                        chainId: chainId,
                        token: token,
                        command: command
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": localStorage.getItem("access-token"),
                        },
                    }
                );
            }
            catch (err) {
                console.log(err);
                setOpenLoading(false);
                setRunningStatus("ready");
            }
        }
    };

    const handleBuyTokens_Order = async (percent = 100, limit = 0) => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            let index = marketIndex;

            buyItems.push({
                address: currentMarket.wallets[index].address,
                initialEthAmount: walletEthBalance[index]
            });

            setRunningStatus("buying");
            axios.post(`${SERVER_URL}/api/v1/market/buy_order`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    percent: percent,
                    limit: limit,
                    orderType: 'buy',
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleSellTokens = async (percent) => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            let index = marketIndex;

            for (let i = 0; i < currentMarket.wallets.length; i++) {
                if (walletChecked[i]) {
                    buyItems.push({
                        address: currentMarket.wallets[i].address,
                        initialEthAmount: walletEthBalance[i]
                    });
                }
            }


            setRunningStatus("selling");
            await axios.post(`${SERVER_URL}/api/v1/market/sell`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    percent: percent,
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleSellTokens_order = async (percent, limit) => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            let index = marketIndex;

            buyItems.push({
                address: currentMarket.wallets[marketIndex].address,
                initialEthAmount: walletEthBalance[index]
            });

            setRunningStatus("selling");
            await axios.post(`${SERVER_URL}/api/v1/market/sell_order`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    orderType: 'sell',
                    percent: percent,
                    limit: limit,
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleDelete_order = async (remove_index) => {
        if (!currentMarket._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            let index = marketIndex;

            buyItems.push({
                address: currentMarket.wallets[marketIndex].address,
                initialEthAmount: walletEthBalance[index]
            });

            setRunningStatus("selling");
            await axios.post(`${SERVER_URL}/api/v1/market/remove_order`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    remove_index: remove_index
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleSaveMarket = async (_zombieWallet = { address: "", privateKey: "" }) => {
        setLoadingPrompt("Saving market...");
        setOpenLoading(true);
        const wallets = currentMarket.wallets.map((item, index) => {
            return {
                address: item.address,
                initialEthAmount: walletEthAmount[index],
            };
        });

        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/market/save`,
                {
                    marketId: currentMarket._id,
                    chainId: chainId,
                    token: cTokenAddress,
                    // token: token,
                    zombie: _zombieWallet,
                    wallets: wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            if (data.success) {
                updateMarket(data.market);
                if (currentMarket._id === data.market._id)
                    setCurrentMarket(data.market);
                toast.success("Market has been saved successfully");
            }
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to save market info");
        }
        setOpenLoading(false);
    };

    const handleRefreshMarket = async () => {
        setLoadingPrompt("Refreshing market...");
        setOpenLoading(true);

        setRefresh(!refresh);

        setTimeout(() => {
            setOpenLoading(false);
        }, 2000);
    };

    const handleOKZombiePrivateKey = (key) => {
        try {
            const wallet = new ethers.Wallet(key);
            setZombieWallet({ address: wallet.address, privateKey: key });
            handleSaveMarket({ address: wallet.address, privateKey: key });
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid private key!");
        }

        setZombieDialog(false);
    };

    const handleDownloadWallets = async () => {
        if (!currentMarket._id) {
            return;
        }

        setLoadingPrompt("Downloading wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/market/download-wallets`,
                {
                    marketId: currentMarket._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            const downloadFile = (data, fileName) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            };

            downloadFile(data, `wallets_market.csv`);
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to download wallets!");
        }
        setOpenLoading(false);
    };

    const handleSetETHAmounts = () => {
        const selectedWallets = walletChecked.filter((item) => item === true);
        if (selectedWallets.length === 0) {
            toast.warn("Please select wallets to set additional ETH amount");
            return;
        }
        setEthAmountDialog(true);
    };


    const handleMouseDown = (e, id) => {
        e.preventDefault();
        setIsDragging(true);
        handleWalletChanged(id, "checked", !walletChecked[id])
    };

    const handleMouseEnter = (id) => {
        if (isDragging) {
            handleWalletChanged(id, "checked", !walletChecked[id])
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleSwap = (percent, limit) => {
        setSwapDialog(false);

        console.log("return to the handleSwap : ", swapData);

        if (swapData.mode % 2 == 0) {
            // Buy tokens in the market order
            if (swapData.mode == 0) {
                handleBuyTokens(percent);
            } else if (swapData.mode == 2) {
                handleBuyTokens_Order(percent, limit);
            }
        } else {
            // Sell tokens all in the market order
            if (swapData.mode == 1) {
                handleSellTokens(percent);
            } else if (swapData.mode == 3) {
                handleSellTokens_order(percent, limit);
            }
        }
    }

    const cancelSwap = () => {
        setSwapDialog(false);
    }

    useEffect(() => {

        setOpenLoading(true);

        setTimeout(() => {
            setOpenLoading(false);
        }, 1000);

    }, [])

    return (
        <div className="flex flex-row">
            <div classname="flex flex-col" style={{ width: '22.5%' }}>
                <MySidebar1 type="onlyLeft" />
                <MySidebar2 type="onlyLeft" />
            </div>
            <NewWalletDialog isOpen={newWalletDialog} onOK={handleOKNewWallet} onCancel={() => setNewWalletDialog(false)} />
            <SwapDialog isOpen={swapDialog} onOK={handleSwap} onCancel={cancelSwap} data={swapData} />

            <ZombieDialog isOpen={zombieDialog} index={0} onOK={handleOKZombiePrivateKey} onCancel={() => setZombieDialog(false)} />
            <EthAmountDialog isOpen={ethAmountDialog} onOK={handleOKMinMaxEthAmounts} onCancel={() => setEthAmountDialog(false)} />

            <div className={`${className} w-full h-full m-8 gap-4`}>
                <div className="font-bold uppercase text-3xl text-white mb-4" >Buy / Sell Bot </div>
                <div className="flex flex-row text-white text-xl items-center my-4">
                    <div className="inline-block"> Token Address : </div>
                    <div className="inline-block flex flex-row items-center justify-center flex-grow ml-2">
                        <input
                            className="w-full outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                            placeholder="Enter Token Address"
                            value={tokenAddress}
                            onKeyDown={(e) => handleEnterPress(e)}
                            onChange={(e) => setTokenAddress(e.target.value)}
                        />
                    </div>
                    <button
                        className={`${showGraph ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 flex flex-row font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                        onClick={handleGraphChange}
                    >
                        {showGraph ? (<><IoIosStarHalf />Hide Graph</>) : (<><IoMdCog /> Show Graph</>)}
                    </button>

                    {/* {showGraph &&
                        <button
                            className={`${showTransaction ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={handleTransactionChange}
                        >
                            {showTransaction ? '🔗 Hide Transactions' : '💎 Show Transactions'}
                        </button>
                    } */}

                </div>

                {showGraph &&
                    <div className="flex">
                        <iframe className={`w-full ${showTransaction ? 'h-[700px]' : 'h-[500px]'} `}
                            src={`https://dexscreener.com/${targetChain}/${cTokenAddress}?embed=1&theme=dark&info=1${showTransaction ? '&trades=1' : '&trades=0'}`}
                        >
                        </iframe>
                    </div>
                }

                <div className="bg-[rgba(0,0,0,0.2)] p-4 my-4 p-8" style={{
                    borderRadius: '0px',
                    margin: '0px'
                }}>

                    <div clasName="flex flex-row my-16" style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px'
                    }}>
                        <button
                            className={`${showOrder == 'market' ? 'bg-[rgba(0,255,0,0.4)]' : 'bg-[rgba(0,255,0,0.1)]'} mr-4 flex flex-row  font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={
                                () => {
                                    setOpenLoading(true);
                                    setTimeout(() => {
                                        handleShowMarket();
                                        setOpenLoading(false);
                                    }, 100);
                                }}
                        >
                            <IoMdSwap className="mr-2" />
                            Market Orders
                        </button>
                        {/* <button
                            className={`${showOrder == 'limit' ? 'bg-[rgba(0,255,0,0.4)]' : 'bg-[rgba(0,255,0,0.1)]'} mr-4 flex flex-row font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={() => {
                                setOpenLoading(true);
                                setTimeout(() => {
                                    handleShowLimit();
                                    setOpenLoading(false);
                                }, 100);
                            }}
                        >
                            <IoMdFlashOff />
                            Limit Orders
                        </button> */}



                        <button
                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button ml-auto"
                            onClick={() => setNewWalletDialog(true)}
                        >
                            <IoIosAddCircle className="text-lg text-green-normal mr-1" />
                            Generate Wallets
                        </button>
                        <button
                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button"
                            onClick={handleDownloadWallets}
                        >
                            <IoIosDownload className="text-lg text-green-normal mr-1" />

                            Download Wallets
                        </button>
                        <button
                            className={`rounded-sm cursor-pointer w-9 h-9 bg-[rgba(0,0,100,0.1)] flex border border-gray-border text-[rgba(0,255,0,0.5)] justify-center items-center ${disabled ? "!bg-gray-highlight text-gray-normal" : "active:scale-95 transition duration-100 ease-in-out transform "}`}
                            disabled={disabled}
                            onClick={() => {
                                handleSaveMarket(zombieWallet);
                            }}>
                            <IoIosSave />

                        </button>
                        <button
                            className={`rounded-sm cursor-pointer w-9 h-9 bg-[rgba(0,0,100,0.1)] flex border border-gray-border text-[rgba(0,255,0,0.5)] justify-center items-center ${disabled ? "!bg-gray-highlight text-gray-normal" : "active:scale-95 transition duration-100 ease-in-out transform "}`}
                            disabled={disabled}
                            onClick={() => {
                                handleRefreshMarket();
                            }}>
                            <IoIosRefresh />
                        </button>
                    </div>

                    <div className="text-white my-8 w-full">

                        <div className="flex flex-col items-center gap-4 w-full p-4 bg-[rgba(0,0,0,0.3)] text-center" >
                            <div className="flex flex-row w-full" style={{ columnGap: '20px', alignItems: 'flex-end' }}>
                                <div className="text-2xl my-4" style={{
                                    letterSpacing: '5px',
                                    fontFamily: 'cursive'
                                }}> Market Trading Wallets </div>
                                <div className="ml-auto">
                                    <div className="col-span-8 md:col-span-4 2xl:col-span-3 ml-auto">
                                        <Popover className="relative flex items-center font-sans text-xs uppercase text-gray-normal">
                                            <div className="whitespace-nowrap">Zombie Wallet<span className="pl-1 text-green-normal">*</span></div>
                                            <Popover.Button className="border border-red-normal text-[6px] flex items-center justify-center cursor-pointer rounded-full w-3 h-3 ml-1">
                                                <FaQuestion className="text-green-normal" />
                                            </Popover.Button>
                                            <Popover.Panel className="absolute z-10 px-2 py-1 text-xs text-center text-white normal-case border rounded-sm bg-gray-highlight bottom-5 border-red-normal">
                                                This wallet distributes ETH to all wallets.
                                            </Popover.Panel>
                                        </Popover>
                                        <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                            <div className={`w-full pr-1 truncate ${zombieWallet.address && "text-white"}`}>
                                                {
                                                    zombieWallet.address ?
                                                        ellipsisAddress(zombieWallet.address) :
                                                        "NOT SET"
                                                }
                                            </div>
                                            <div className="flex items-center text-base">
                                                {zombieWallet.address && !copied["zombie_wallet_0"] &&
                                                    <FaRegCopy className="w-4 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => copyToClipboard("zombie_wallet_0", zombieWallet.address)} />
                                                }
                                                {zombieWallet.address && copied["zombie_wallet_0"] &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                    </svg>
                                                }
                                                {!disabled && <FaEllipsisV className="w-4 ml-1 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => handleSetZombieWallet()} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-2 lg:items-center lg:flex-row">
                                    <button
                                        className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button"
                                        disabled={disabled}
                                        onClick={handleSetETHAmounts}
                                    >
                                        <FaEthereum className="text-sm text-green-normal" />
                                        &nbsp; Set ETH amount
                                    </button>
                                </div>
                            </div>
                            <div className="flex w-full max-h-[500px] " style={{
                                overflow: 'auto'
                            }}>
                                <table className="min-w-[700px] max-h-[500px] w-full text-xs" style={{}}>
                                    <thead className=" text-gray-normal">
                                        <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10 h-8">
                                            <th className="w-8 text-center">
                                                <div className="flex items-center justify-center">
                                                    <input type="checkbox"
                                                        className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                        checked={walletAllChecked}
                                                        onChange={handleWalletAllChecked} />
                                                </div>
                                            </th>
                                            <th className="w-8">
                                                <p className="leading-none text-center">
                                                    #
                                                </p>
                                            </th>
                                            <th className="text-center">
                                                <p className="leading-none text-center">
                                                    Address
                                                </p>
                                            </th>
                                            <th className="w-[15%] text-center">
                                                <p className="leading-none text-left">
                                                    ETH Balance
                                                </p>
                                            </th>
                                            <th className="w-[15%] text-center">
                                                <p className="leading-none text-left">
                                                    Token Balance
                                                </p>
                                            </th>

                                            <th className="w-[15%]">
                                                <p className="leading-none text-center">
                                                    Disperse ETH
                                                </p>
                                            </th>

                                            <th className="w-[10%]">
                                                <p className="leading-none text-center">
                                                    Buy
                                                </p>
                                            </th>
                                            <th className="w-[10%]">
                                                <p className="leading-none text-center">
                                                    Sell
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs text-white" >
                                        {
                                            currentMarket.wallets &&
                                            currentMarket.wallets.map((item, index) => {
                                                return (
                                                    <tr key={index}
                                                        className={`${index % 2 === 1 && "bg-[#ffffff02]"} hover:bg-[#ffffff08] ${walletChecked[index] && "!bg-[#00000030]"} h-8`}
                                                    >
                                                        <td className="text-center"
                                                            onMouseDown={(e) => handleMouseDown(e, index)}
                                                            onMouseEnter={() => handleMouseEnter(index)}
                                                            onMouseUp={handleMouseUp}>
                                                            <div className="flex items-center justify-center">
                                                                <input type="checkbox"
                                                                    className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0 cursor-pointer"
                                                                    checked={walletChecked[index]}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td className="">
                                                            <p className="leading-none text-center text-gray-normal">
                                                                {index + 1}
                                                            </p>
                                                        </td>
                                                        <td className="">
                                                            <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                <p className="bg-transparent border-none outline-none">
                                                                    {/* {item.address} */}
                                                                    {ellipsisAddress(item.address, 12)}
                                                                </p>
                                                                {
                                                                    copied["wallet_" + index] ?
                                                                        (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                        </svg>) :
                                                                        (<FaRegCopy className="w-3 h-3 transition duration-100 ease-in-out transform cursor-pointer active:scale-95"
                                                                            onClick={() => copyToClipboard("wallet_" + index, item.address)} />
                                                                        )
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="">
                                                            <p className="flex items-center justify-start text-yellow-normal">
                                                                <FaEthereum className="mr-1 opacity-50 text-gray-normal" />
                                                                {walletEthBalance[index]}
                                                            </p>
                                                        </td>
                                                        <td className="">
                                                            <p className="flex items-center justify-start text-white">
                                                                <FaDatabase className="mr-1 opacity-50 text-xxs text-gray-normal" />
                                                                <span>{Number(walletTokenBalance[index]?.split(".")[0] ?? "0").toLocaleString()}</span>
                                                                <span className="font-normal text-gray-normal">.{walletTokenBalance[index]?.split(".")[1] ?? "00"}</span>
                                                            </p>
                                                        </td>
                                                        {/* <td className="text-center">
                                                                <input
                                                                    className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[150px] h-[26px]"
                                                                    disabled={disabled}
                                                                    value={walletTokenAmount[index]}
                                                                    onChange={(e) => handleWalletChanged(index, "token_amount", e.target.value)} />
                                                            </td> */}

                                                        <td className="text-center">
                                                            <input
                                                                className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[100px] h-[26px]"
                                                                disabled={disabled}
                                                                value={walletEthAmount[index]}
                                                                onChange={(e) => handleWalletChanged(index, "eth_amount", e.target.value)} />
                                                        </td>

                                                        <td className="text-center hover:brightness-50 cursor-pointer h-[40px] justify-center items-center p-[20px] "
                                                            onClick={() => {
                                                                setMarketIndex(index);
                                                                setSwapData({
                                                                    mode: showOrder == 'market' ? 0 : 2,
                                                                    condition: showOrder != 'market',
                                                                    limit: 0,
                                                                    wallets: [{
                                                                        address: currentMarket.wallets[index].address,
                                                                        amount: Number(walletEthBalance[index])
                                                                    }],
                                                                    tokenAddress: cTokenAddress
                                                                })
                                                                setSwapDialog(true);
                                                            }} >
                                                            <button
                                                                className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button ml-auto"
                                                                style={{ background: 'rgba(0,255,0,0.1)', color: 'white' }}
                                                            >
                                                                <IoIosCart className="text-lg text-green-normal mr-2" />
                                                                Buy
                                                            </button>
                                                        </td>

                                                        <td className="text-center hover:brightness-50 cursor-pointer h-[40px] justify-center items-center p-[20px] "
                                                        >

                                                            <button
                                                                className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button ml-auto"
                                                                style={{ background: 'rgba(255,0,0,0.1)', color: 'white' }}
                                                                onClick={() => {
                                                                    setMarketIndex(index);
                                                                    if (showOrder == 'market') {
                                                                        // handleSellTokens(100);
                                                                        setSwapData({
                                                                            mode: 1,
                                                                            condition: showOrder != 'market',
                                                                            limit: 0,
                                                                            wallets: [{
                                                                                address: currentMarket.wallets[index].address,
                                                                                amount: Number(walletTokenBalance[index]),
                                                                            }],
                                                                            tokenAddress: cTokenAddress
                                                                        })
                                                                        setSwapDialog(true);
                                                                    } else {
                                                                        setSwapData({
                                                                            mode: 3,
                                                                            condition: showOrder != 'market',
                                                                            limit: 0,
                                                                            wallets: [{
                                                                                address: currentMarket.wallets[index].address,
                                                                                amount: Number(walletTokenBalance[index]),
                                                                            }],
                                                                            tokenAddress: cTokenAddress
                                                                        })
                                                                        setSwapDialog(true);
                                                                    }
                                                                }}
                                                            >
                                                                <IoIosRainy className="text-lg text-green-normal mr-2" />
                                                                Sell
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="relative flex w-full items-center justify-between h-full gap-3 mt-3 text-white bg-transparent bg-clip-border">
                                <div className="flex items-center grow">
                                    <div className="flex items-center font-sans text-gray-normal">
                                        <FaGasPump className="text-sm" />
                                        <span className="pl-2 font-medium text-white">{gasPrice}</span>
                                        <IoIosClose className="p-1 text-3xl text-gray-normal" />
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-1.5 bg-transparent w-14 h-[24px]"
                                            placeholder="125%"
                                            value={gasPriceMultiplier}
                                            onChange={(e) => setGasPriceMultiplier(e.target.value)}
                                        />
                                        <button className="flex items-center justify-center w-6 h-6 ml-1 transition duration-100 ease-in-out transform rounded-full bg-gray-highlight active:scale-90" onClick={handleQueryGasPrice}>
                                            <IoIosRefresh className="text-xs font-bold cursor-pointer text-gray-normal" />
                                        </button>
                                    </div>
                                    <div className="ml-3 font-sans text-xs uppercase text-gray-normal whitespace-nowrap">
                                        Target Wallet:
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                                        placeholder="Target Wallet Address"
                                        value={targetWallet}
                                        onChange={(e) => setTargetWallet(e.target.value)}
                                    />
                                </div>
                                <div className="flex items-center gap-3">
                                    <button
                                        className="flex flex-row font-sans text-xs border border-gray-border  font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                        style={{ background: 'rgba(0,255,0,0.1)', color: 'white' }}
                                        onClick={() => {
                                            let checkedWallets = currentMarket.wallets.filter((item, index) => walletChecked[index] == true)
                                                .map((wallet, index) => ({
                                                    ...wallet,
                                                    amount: walletEthBalance[index]
                                                }));

                                            setSwapData({
                                                mode: showOrder == 'market' ? 0 : 2,
                                                condition: showOrder != 'market',
                                                limit: 0,
                                                wallets: checkedWallets,
                                                tokenAddress: cTokenAddress
                                            })

                                            setSwapDialog(true);


                                        }}
                                    >
                                        <IoIosCart />
                                        <span className="mx-1"> Buy</span>
                                        <IoMdKeypad />
                                    </button>
                                    <button
                                        className="flex flex-row font-sans text-xs border border-gray-border  font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                        style={{ background: 'rgba(255,0,0,0.1)', color: 'white' }}
                                        onClick={() => {
                                            let checkedWallets = currentMarket.wallets.filter((item, index) => walletChecked[index] == true)
                                                .map((wallet, index) => ({
                                                    ...wallet,
                                                    amount: walletTokenBalance[index]
                                                }));

                                            setSwapData({
                                                mode: showOrder == 'market' ? 1 : 3,
                                                condition: showOrder != 'market',
                                                limit: 0,
                                                wallets: checkedWallets,
                                                tokenAddress: cTokenAddress
                                            })

                                            setSwapDialog(true);
                                        }}
                                    // onClick={handleBatchSell}
                                    >
                                        <IoIosRainy />
                                        <span className="mx-1"> Sell</span>
                                        <IoMdKeypad />
                                    </button>
                                    <button
                                        className="flex flex-row font-sans text-xs border border-gray-border  font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                        style={{
                                            background: 'rgba(255,255,100,0.1)'
                                        }}
                                        onClick={handleCollectAllEth}
                                    >
                                        <IoMdBonfire />
                                        Collect ETH
                                    </button>
                                    <div className="w-[1px] h-6 border-r border-gray-normal opacity-40 mx-1"></div>

                                    <button
                                        className="ml-auto font-sans text-xs flex flex-row border border-gray-border font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-100 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                        disabled={disabled}
                                        onClick={handleDisperseETH}
                                        style={{
                                            background: 'rgba(50,0,100,0.1)'
                                        }}
                                    >
                                        <IoMdFastforward />
                                        DISPERSE ETH
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showOrder == 'market' &&
                            // Market Order Page
                            <>


                                {/* <div className="flex flex-row gap-8 w-full">
                                    <div className="text-xl flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> {nativeCoin} Price Now </span>
                                    </div>
                                    <div className="text-xl flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Token Price Now </span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-8 mt-4 w-full">
                                    <div className="flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Buy Amount </span>
                                        <input
                                            className="flex-grow outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                            placeholder="Enter Buy Amount "
                                        />
                                        <button
                                            className={`${true ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                    <div className="flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Sell Amount </span>
                                        <input
                                            className="flex-grow outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                            placeholder="Enter Buy Amount "
                                        />
                                        <button
                                            className={`${false ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                        >
                                            Sell Now
                                        </button>
                                    </div>
                                </div> */}
                            </>
                        }

                        {showOrder == 'limit' &&
                            // Limit Order Page
                            <>
                                <div className="flex flex-row gap-8 mt-4 w-full">

                                    {/* <div className="flex flex-col items-center w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <div className="text-2xl mb-2"> Limit Order Operations</div>

                                        <div className="flex flex-row items-center p-4 justify-between w-full">
                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Limit Price : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Buy Amount "
                                                />
                                            </div>

                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Buy Amount : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Buy Amount "
                                                />
                                            </div>
                                            <div className="flex-col flex items-start justfiy-end">
                                                <span className="mb-1 text-center"> &nbsp; </span>
                                                <button
                                                    className={`w-[140px] ${true ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                                >
                                                    Order Buy
                                                </button>
                                            </div>
                                        </div>

                                        <div className="flex flex-row items-center p-4 justify-between w-full">
                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Limit Price : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Limit Price "
                                                />
                                            </div>

                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Sell Amount : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Sell Amount "
                                                />
                                            </div>
                                            <div className="flex-col flex items-start justfiy-end">
                                                <span className="mb-1 text-center"> &nbsp; </span>
                                                <button
                                                    className={`w-[140px] ${false ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                                >
                                                    Order Sell
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="flex flex-col items-center gap-4 w-full text-center"
                                    >
                                        <div classname="flex flex-row w-full" style={{
                                            width: '100%', display: 'flex'
                                        }}>
                                            <div className="text-2xl my-4" style={{
                                                display: 'flex',
                                                letterSpacing: '5px',
                                                fontFamily: 'cursive'
                                            }}> Limit Order Table</div>
                                            <div className="ml-auto mr-2">
                                                <button
                                                    className="flex flex-row mt-4 items-center justify-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full mx-2"
                                                    style={{ background: 'rgba(0,0,255,0.1)', color: 'white', letterSpacing: '10px' }}
                                                    onClick={handleCommand_Order}
                                                >
                                                    {currentMarket && currentMarket.status == 'ready' ?
                                                        <>
                                                            <IoIosAirplane className="text-lg text-green-normal mr-4" />
                                                            START
                                                        </> : <>
                                                            <IoIosCloseCircle className="text-lg text-red mr-4" />
                                                            STOP
                                                        </>
                                                    }

                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex w-full max-h-[500px] " style={{
                                            overflow: 'auto'
                                        }}>
                                            <table className="min-w-[700px]  w-full text-xs" style={{}}>
                                                <thead className=" text-gray-normal">
                                                    <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                                                        <th className="w-8">
                                                            <p className="leading-none text-center">
                                                                #
                                                            </p>
                                                        </th>
                                                        <th className="w-[20%]">
                                                            <p className="leading-none text-center">
                                                                Address
                                                            </p>
                                                        </th>
                                                        <th className="w-[20%]">
                                                            <p className="leading-none text-center">
                                                                Token Address
                                                            </p>
                                                        </th>
                                                        <th className="w-[20%] text-center">
                                                            <p className="leading-none text-center">
                                                                Order Type
                                                            </p>
                                                        </th>

                                                        <th className="w-[10%] text-center">
                                                            <p className="leading-none text-left">
                                                                Swap Amount
                                                            </p>
                                                        </th>

                                                        <th className="w-[10%] text-center">
                                                            <p className="leading-none text-left">
                                                                Limit Price
                                                            </p>
                                                        </th>

                                                        <th className="w-[10%]">
                                                            <p className="leading-none text-center">
                                                                Status
                                                            </p>
                                                        </th>
                                                        <th className="w-[10%]">
                                                            <p className="leading-none text-center">
                                                                Operation
                                                            </p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-xs text-white" >
                                                    {
                                                        currentMarket.orders &&
                                                        currentMarket.orders.map((item, index) => {
                                                            return (
                                                                <tr key={index}
                                                                    className={`${index % 2 === 1 && "bg-[#ffffff02]"} hover:bg-[#ffffff08] ${walletChecked[index] && "!bg-[#00000030]"} h-8`}
                                                                >
                                                                    <td className="">
                                                                        <p className="leading-none text-center text-gray-normal">
                                                                            {index + 1}
                                                                        </p>
                                                                    </td>
                                                                    <td className="">
                                                                        <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                            <p className="bg-transparent border-none outline-none">
                                                                                {/* {item.address} */}
                                                                                {ellipsisAddress(item.wallet.address, 0)}
                                                                            </p>
                                                                            {
                                                                                copied["wallet_" + index] ?
                                                                                    (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                                    </svg>) :
                                                                                    (<FaRegCopy className="w-3 h-3 transition duration-100 ease-in-out transform cursor-pointer active:scale-95"
                                                                                        onClick={() => copyToClipboard("wallet_" + index, item.wallet.address)} />
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="">
                                                                        <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                            <p className="bg-transparent border-none outline-none">
                                                                                {/* {item.address} */}
                                                                                {ellipsisAddress(item.tokenAddress, 0)}
                                                                            </p>
                                                                            {
                                                                                copied["wallet_" + index] ?
                                                                                    (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                                    </svg>) :
                                                                                    (<FaRegCopy className="w-3 h-3 transition duration-100 ease-in-out transform cursor-pointer active:scale-95"
                                                                                        onClick={() => copyToClipboard("wallet_" + index, item.tokenAddress)} />
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center cursor-pointer flex justify-center items-center p-[5px] h-full w-full">

                                                                        {item.orderType == 'sell' &&
                                                                            <button
                                                                                className="flex flex-row mt-4 items-center justify-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full mx-2"
                                                                                style={{ background: 'rgba(255,0,0,0.1)', color: 'white' }}

                                                                            >
                                                                                <IoIosRainy className="text-lg text-green-normal mr-2" />
                                                                                Sell Ordered
                                                                            </button>
                                                                        }

                                                                        {item.orderType == 'buy' &&
                                                                            <button
                                                                                className="flex flex-row mt-4 items-center justify-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full mx-2"
                                                                                style={{ background: 'rgba(0,255,0,0.1)', color: 'white' }}
                                                                            >
                                                                                <IoIosCart className="text-lg text-green-normal mr-2" />
                                                                                Buy Ordered
                                                                            </button>

                                                                        }

                                                                    </td>

                                                                    <td className="">
                                                                        <p className="flex items-center justify-start text-yellow-normal">
                                                                            <FaEthereum className="mr-1 opacity-50 text-gray-normal" />
                                                                            {Number(item.wallet.swapAmount).toFixed(4)} ETH
                                                                        </p>
                                                                    </td>

                                                                    <td className="">
                                                                        <p className="flex items-center justify-start text-yellow-normal">
                                                                            <FaEthereum className="mr-1 opacity-50 text-gray-normal" />
                                                                            {Number(item.limit).toFixed(4)} ETH
                                                                        </p>
                                                                    </td>
                                                                    <td >
                                                                        <div className="flex justify-center items-center">
                                                                            <img src="/assets/hourglass-svg.svg"
                                                                                className={`w-8 h-8 ${item.status == 'running' ? 'rotating' : ''}`} alt="spinner" />
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className="text-center">
                                                                <input
                                                                        className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[150px] h-[26px]"
                                                                        disabled={disabled}
                                                                        value={walletTokenAmount[index]}
                                                                        onChange={(e) => handleWalletChanged(index, "token_amount", e.target.value)} />
                                                                </td> */}
                                                                    <td className="text-center hover:brightness-50 cursor-pointer justify-center items-center p-[20px] "
                                                                    >
                                                                        <button
                                                                            className="flex flex-row items-center outline-none border border-gray-border text-gray-normal px-4 rounded-[10px] font-sans text-sm h-button w-full"
                                                                            style={{ background: 'rgba(255,0,0,0.1)', color: 'white' }}
                                                                            onClick={() => {
                                                                                handleDelete_order(index);
                                                                            }}
                                                                        >
                                                                            <IoIosRadioButtonOn className="text-red mr-2 text-red-700" />
                                                                            Delete

                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <table class="w-full border-collapse m-auto">
                                            <thead class="bg-[rgba(255,0,255,0.1)] border text-white">
                                                <tr class="h-16">
                                                    <th class="border w-[20px] px-4">No</th>
                                                    <th class="border px-4">Limit</th>
                                                    <th class="border px-4">Amount</th>
                                                    <th class="border px-4">Type</th>
                                                    <th class="border px-4">...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="h-12 hover:bg-gray-10">
                                                    <td class=" w-[20px] border px-4">1</td>
                                                    <td class="border px-4">1.5$</td>
                                                    <td class="border px-4">100$</td>
                                                    <td class="border px-4 bg-[rgba(0,255,0,0.1)]"> buy</td>
                                                    <td class="border px-4">Active</td>
                                                </tr>
                                                <tr class="h-12 hover:bg-gray-10">
                                                    <td class="w-[20px] border px-4">2</td>
                                                    <td class="border px-4">2.0$</td>
                                                    <td class="border px-4">100$</td>
                                                    <td class="border px-4 bg-[rgba(255,0,0,0.2)]">sell</td>
                                                    <td class="border px-4">Active</td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>

                <div className="flex" style={{ height: '500px', overflowY: 'auto' }}>
                    <iframe className={`w-full ${true ? 'h-[700px]' : 'h-[500px]'} `}
                        src={`https://dexscreener.com/${targetChain}/${cTokenAddress}?embed=1&theme=dark&info=0&chart=0&${true ? '&trades=1' : '&trades=0'}`}
                    >
                    </iframe>
                </div>
            </div>

        </div >
    );
}

/*
* embed: Set to 1 to enable embedding (e.g. embed=1)
* theme: Choose between "light" and "dark" themes (e.g. theme=dark)
* info: Toggle general information display, set to 0 or 1 (e.g. info=1)
* trades: Toggle trades/transaction history display, set to 0 or 1 (e.g. trades=1)
* chart: Toggle price chart display, set to 0 or 1 (e.g. chart=1)
* trades_type: Filter trade types, options include "all", "buys", or "sells" (e.g. trades_type=buys)
* price: Toggle price information display, set to 0 or 1 (e.g. price=1)
* market: Toggle market information display, set to 0 or 1 (e.g. market=1)
* tvl: Toggle Total Value Locked display, set to 0 or 1 (e.g. tvl=1)
* pool: Display pool information, set to 0 or 1 (e.g. pool=1)
* currency: Set the display currency, e.g. "USD", "EUR", "JPY" (e.g. currency=EUR)
* interval: Set the chart interval, options like "5m", "15m", "1h", "4h", "1d" (e.g. interval=1h)
* header: Toggle header display, set to 0 or 1 (e.g. header=1)
* tokenomics: Display tokenomics information, set to 0 or 1 (e.g. tokenomics=1)
* transactions: Set the number of transactions to display (e.g. transactions=50)
*/